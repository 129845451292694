// TimerManager.js  
import { ref, onUnmounted } from 'vue';  

const timer = ref(null);  

export function useGlobalTimer(callback) {  
  // 每秒执行回调  
  const start = () => {  
    if (timer.value) return; // 如果已存在计时器，则不再创建  
    timer.value = setInterval(() => {  
      callback();  
    }, 1000);  
  };  

  const stop = () => {  
    clearInterval(timer.value);  
    timer.value = null;  
  };  

  onUnmounted(() => {  
    stop(); // 组件卸载时停止计时器  
  });  

  return { start, stop };  
}